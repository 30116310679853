<template>
  <div>
    <v-container>
      <h2 class="mt-5 mb-5 grey--text text--darken-1"><router-link to="/projects" class="white--text">Projects</router-link> / {{ project.title }}</h2>
      <v-layout row wrap>
        <v-flex md12 lg12>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Title</td>
                  <td>{{ project.title }}</td>
                </tr>
                <tr>
                  <td>Tagline</td>
                  <td>{{ project.tagline }}</td>
                </tr>
                <tr>
                  <td>Quote</td>
                  <td>{{ project.quote }}</td>
                </tr>
                <tr>
                  <td>Client</td>
                  <td>{{ project.client }}</td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>{{ project.location }}</td>
                </tr>
                <tr>
                  <td>Project Area</td>
                  <td>{{ project.projectArea }}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{{ project.description }}</td>
                </tr>
                <tr>
                  <td>Website</td>
                  <td>{{ project.website }}</td>
                </tr>
                <tr>
                  <td>Visibility</td>
                  <td>{{ project.visibility }}</td>
                </tr>
                <tr>
                  <td>Featured</td>
                  <td>{{ project.featured }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-flex md12 lg12>
            <v-layout row wrap>
              <h2 class="mt-5 mb-5">Gallery</h2>
              <v-flex xs12></v-flex>
              <v-flex
                xs12
                md4
                v-for="image in project.images"
                :key="image"
                @click="viewImage(image)"
                class="pa-2 picture"
              >
                <div class="pa-2 pb-0 grey darken-4">
                  <img
                    :src="image"
                    alt=""
                    style="max-width: 100%"
                    class="mb-0 pb-0"
                  />
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialog" width="900">
      <v-card class="pa-2 pb-0">
        <img :src="selectedImage" style="width: 100%;"/>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import Axios from "axios";
import { BASE_URL } from "../config";
export default {
  data() {
    return {
      project: {},
      selectedImage : '',
      dialog : false
    };
  },
  mounted() {
    this.fetchProject();
  },

  methods: {
    async fetchProject() {
      let url = BASE_URL + "/project/" + this.$route.params.id;
      let { data } = await Axios.get(url);
      this.project = data;
    },
    viewImage(image){
      this.selectedImage = image
      this.dialog = true
    }
  },
};
</script>

<style>
  .picture img{
    cursor: -moz-zoom-in; 
    cursor: -webkit-zoom-in; 
    cursor: zoom-in;
}
</style>